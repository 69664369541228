import * as React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import {DataProvider} from "../contentful/data-provider";

const CcpaPage = ({data}) => {
    const dataProvider = new DataProvider(data);
    return (
        <Layout dataProvider={dataProvider}>
            <Helmet>
                <title>GlassView - CCPA</title>
            </Helmet>
            <main className="pt-24 lg:pt-24">
                <section className="ccpa-section pb-[30px] md:pb-[120px]">
                    <div className={'container'}>
                        <h1 className={`text-4xl font-bold`}>CCPA</h1>
                        <h2>GlassView California Consumer Privacy Act of 2018 (CCPA)</h2>
                        <p>
                            On January 1, 2020, the California Consumer Privacy Act of 2018 (CCPA) took effect, setting new
                            requirements and rights relating to personal information of California consumers. GlassView
                            invests great efforts in protecting user privacy as well as understanding and exercising of
                            rights under the CCPA.
                        </p>
                        <p>
                            The data that GlassView processes may include information that may relate to users in
                            California. Consequently, it has addressed the requirements under California data protection
                            laws, and specifically those outlined in CCPA.
                        </p>
                        <p>
                            As part of this effort, GlassView implemented changes to its processes, documentation,
                            policies, and contractual framework with its customers and publishers in which campaigns will
                            deliver on. GlassView has also reinforced its policy on opting-out, data retention and subject
                            rights.
                        </p>
                        <h2>GlassView’s Compliance with CCPA</h2>
                        <p>
                            Some of the publishers GlassView partners with can potentially collect data to provide
                            California users with targeted and relevant advertising.
                        </p>
                        <p>
                            Because GlassView does not directly collect data, it asks that all publishers obtain a separate
                            CCPA consent from end users to collect and process end-users’ personal information for the
                            purpose of serving targeted ads, i.e., these publishers obtain consent from users for ad
                            targeting. This reflects GlassView’s position as a platform that facilitates the serving
                            of ads as an intermediary between supply and demand.
                        </p>
                        <p>
                            GlassView uses Google Ads to serve all ads to publishers. Users who wish to opt out of seeing
                            ads from GlassView can follow the steps outlined under the "User Consent" section of this
                            article.
                        </p>
                        <p><em>What personal data does GlassView process?</em></p>
                        <p>
                            While GlassView does not directly collect personal data from our users, it does work with
                            publishers that process information such as the following:
                        </p>
                        <ul>
                            <li>Internet Protocol (IP) addresses</li>
                            <li>Advertising ID</li>
                            <li>Internet Service Providers (ISP)</li>
                            <li>Precise (GPS) location data</li>
                            <li>Registration information</li>
                        </ul>
                        <p><em>Is any of the processed data transferred outside of California?</em></p>
                        <p>
                            Yes, GlassView works with publishers that transfer data outside of California, but has
                            implemented certain contractual safeguards with publishers requiring them to process data in
                            compliance with the CCPA.
                        </p>
                        <h2>User Consent</h2>
                        <p><em>How does a user withdraw consent?</em></p>
                        <p>
                            GlassView’s campaigns are delivered by the vendor Google Ads. Google Ads places cookies on
                            browsers to facilitate serving particular ads – for instance, to help determine whether users
                            have seen a particular advertisement before, to tailor ads based on previously visited sites
                            and to avoid sending duplicate advertisements. Users can opt out of Google Ads use of cookies
                            for these purposes by visiting <a href="http://www.google.com/intl/en/privacy." target={`_blank`}>http://www.google.com/intl/en/privacy.</a>
                        </p>
                        <p>
                            In some cases, GlassView will work with other third-party vendors to help deliver advertisements
                            tailored to user interests. These vendors include ad networks and audience segment providers who
                            use cookies, beacons, mobile ad identifiers, and similar technologies to collect information
                            about online activity (i.e., sites and pages visited and mobile apps used) in order to help
                            advertisers deliver particular ads on relevant sites. Users can opt out of vendor cookies used
                            to tailor advertising by visiting <a href="http://www.aboutads.info/" target={`_blank`}>http://www.aboutads.info</a>.
                        </p>
                        <p>
                            Due to differences between using apps and websites on mobile devices, users may need to take
                            additional steps to disable tracking technologies in mobile apps.  Many mobile devices allow
                            opting-out of targeted advertising for mobile apps using the settings within the mobile app
                            or mobile device. More information can be found in a device’s mobile settings. Users may also
                            uninstall apps using the standard uninstall process available on the mobile device or app
                            marketplace.
                        </p>
                        <p>
                            Often advertisers contract with a third-party service to host their ads. In this case, an ad
                            serving vendor contacts the advertisers’ hosting service for a particular advertisement. In
                            that case, an independent cookie may be used by the third-party service. GlassView does not
                            have a mechanism to allow visitors to opt-out of cookies from vendors with whom GlassView does
                            not have a contractual relationship.
                        </p>
                        <p><em>What happens if a user withdraws his/her consent?</em></p>
                        <p>
                            If a user withdraws consent through a publisher, the user will be treated as if consent has
                            been declined. In such cases, publishers will anonymize any collected data before passing the
                            ad request for ad delivery, to ensure that no personal information is processed. The user will
                            receive contextual ads only.
                        </p>
                        <p>
                            If the user chooses to opt out of GlassView ads completely, by using the process outlined under
                            the "How does a user withdraw consent?" section, ads will no longer be targeted to that user
                            from GlassView via Google Ads.
                        </p>
                        <p><em>What are contextual ads?</em></p>
                        <p>
                            Contextual ads are ones that are based on the context of a site or an app rather than on the
                            behavioral preferences, or personal data of a particular user.
                        </p>
                        <h2>Data Retention Policy</h2>
                        <p>
                            GlassView does not collect user data from California residents, but has asked its publishers
                            (a) not to store user data for any purpose other than for internal reporting and analysis and
                            for providing its services and (b) to store such data only so long as the user uses the app or
                            website plus 30 days thereafter, except for purposes of invoicing, reporting, discrepancies or
                            fraud prevention, and in any case for no longer than 45 days thereafter.
                        </p>
                        <h2>Data Subject Rights</h2>
                        <p>
                            Individuals have rights concerning Personal Data. If users wish to exercise rights under any
                            applicable law, including the California Consumer Privacy Act (CCPA), such as the right to
                            restrict or object to such Personal Data’s processing, or to port such Personal Data, or the
                            right to equal services and prices (each to the extent available to you under the laws which
                            apply) – please contact GlassView by email at <a href="mailto:privacy@glassview.com.">privacy@glassview.com</a>.
                        </p>
                        <p>
                            For more information on GlassView’s privacy policy, please click <a href="https://glassview.com/privacy-policy" target={`_blank`}>here</a>.
                        </p>
                        <h2>Contact Us</h2>
                        <p>
                            If any user has questions or comments about GlassView and CCPA compliance, please contact
                            GlassView by email at <a href="mailto:privacy@glassview.com.">privacy@glassview.com</a> or by
                            mail at the HQ address: <br/> 813 May St Suite 3, Fort Worth, TX 76104
                        </p>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default CcpaPage;

export const query = graphql`
    query {
        allContentfulMenu(filter: {node_locale: {eq: "en-US"}}) {
            edges {
                node {
                    ... Menus
                }
            }
        }
    }
`;
